import React, { Fragment, useCallback, useContext, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  DialogContentText,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import Authentication from "../../services/authentication.service";
import { Auth, MeUser } from "ordercloud-javascript-sdk";
import { RegisterFormValues } from "../../services/authentication.service";
import { SessionContext } from "../../providers/session";
import PhoneMaskInput from "./PhoneMaskInput";
import DoubleOutlinedBtn from "./DoubleOutlinedBtn";
import { cloneDeep } from "lodash";
import bachmansIntegrationsService from "../../services/bachmansIntegrations.service";
import PasswordRequirementsList from "../Authentication/PasswordRequirementsList";
import appConfig, { scope } from "../../constants/app.constants";
import { regexConstants } from "../../constants/regex.constants";
import { Loyalty } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    flexRow: {
      display: "flex",
      flexFlow: "row wrap",
      margin: theme.spacing(0, -1),
      alignItems: "center",
      "& > *": {
        flex: "1 1 auto",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        flexFlow: "row nowrap",
      },
      "& .MuiFormHelperText-root": {
        marginBottom: theme.spacing(0.5), //DESIGN: if there is an error message on one of the inputs, we won't lose the vertical spacing between inputs
      },
    },
  })
);

const buildValidation = (fields?: UserInputFields[]) => {
  const obj: any = {};
  if (!fields || fields.includes("FirstName")) {
    obj.FirstName = yup
      .string()
      .matches(regexConstants.validName, "Special characters and numbers not allowed in name input")
      .required("First Name is required");
  }
  if (!fields || fields.includes("LastName")) {
    obj.LastName = yup
      .string()
      .matches(regexConstants.validName, "Special characters and numbers not allowed in name input")
      .required("Last Name is required");
  }
  if (!fields || fields.includes("Email")) {
    obj.Email = yup.string().email("Enter a valid Email address").required("Email is required");
  }
  if (!fields || fields.includes("Phone")) {
    obj.Phone = yup
      .string()
      .matches(regexConstants.validPhone, "Valid phone number required")
      .required("Phone number is required");
  }
  if (fields?.includes("CurrentPassword")) {
    obj.CurrentPassword = yup.string().required("Please validate your current password");
  }
  if (!fields || fields.includes("Password")) {
    obj.Password = yup
      .string()
      .matches(regexConstants.validPassword, " Please make sure your password follows our guidelines")
      .required("Password is required");
    obj.PasswordConfirmation = yup
      .string()
      .matches(regexConstants.validPassword, "Please make sure your password follows our guidelines")
      .required("Password confirmation required")
      .oneOf([yup.ref("Password")], "Password must match");
  }
  const validation = yup.object(obj);
  return validation;
};

export type UserInputFields = "FirstName" | "LastName" | "Email" | "Password" | "Phone" | "CurrentPassword";

export interface UserInputFormProps {
  type: "register" | "update";
  initialUser?: MeUser;
  fields?: UserInputFields[];
  buttonPosition?: "left" | "center" | "right";
  onCancel?: () => void;
  onComplete?: () => void;
  onError?: (err?: any) => void;
}

const UserInputForm: React.FunctionComponent<UserInputFormProps> = (props) => {
  const classes = useStyles();
  const { patchUser, setUserTokens, login, user } = useContext(SessionContext);
  const [passwordResetError, setPasswordResetError] = useState<string>();

  const handleRegister = useCallback(
    async (values: RegisterFormValues, actions: FormikHelpers<RegisterFormValues>) => {
      // ! TODO Remove this Function
      // const getLoyaltyID = (currentUser: MeUser) => {
      //   return cloneDeep(currentUser?.Phone || "")
      //     .replace(/[^\w\s]/gi, "")
      //     .replace(" ", "");
      // };

      if (setUserTokens) {
        try {
          let results = await Authentication.RegisterAsync(values);
          setUserTokens(results.Token);

          if (user && patchUser) {
            await patchUser({ xp: { LoyaltyCardNumber: "" } });
          }
          if (props.onComplete) {
            props.onComplete();
          }
        } catch (err) {
          if (props.onError) props.onError(err);
        }
      }
    },
    [patchUser, props, setUserTokens, user]
  );

  const handlePatchUser = async (values: RegisterFormValues, actions: FormikHelpers<RegisterFormValues>) => {
    const patchObj: Partial<MeUser> = {};
    if (values.FirstName !== props.initialUser?.FirstName && hasField("FirstName")) {
      patchObj.FirstName = values.FirstName;
    }
    if (values.LastName !== props.initialUser?.LastName && hasField("LastName")) {
      patchObj.LastName = values.LastName;
    }
    if (values.Email !== props.initialUser?.Email && hasField("Email")) {
      patchObj.Email = values.Email;
      patchObj.Username = values.Email;
    }
    if (values.Phone !== props.initialUser?.Phone && hasField("Phone")) {
      patchObj.Phone = values.Phone;
    }
    if (values.Password !== props.initialUser?.Password && hasField("Password")) {
      patchObj.Password = values.Password;
    }
    if (patchUser && login) {
      if (values.CurrentPassword !== "" && hasField("CurrentPassword")) {
        try {
          await Auth.Login(props.initialUser?.Username!, values.CurrentPassword, appConfig.clientID, scope);
          await patchUser(patchObj);
          setPasswordResetError(undefined);
        } catch (err: any) {
          if (err.errors?.error === "invalid_grant") {
            setPasswordResetError("Current password is invalid");
          }
          //setPasswordResetError(err.errors?.Errors?.[0].Message)
        }
      } else {
        await patchUser(patchObj);
      }
    }
  };

  const hasField = (field: UserInputFields) => {
    if (field === "CurrentPassword" && props?.type !== "update") {
      return false;
    } else {
      return !props.fields || props.fields.includes(field);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const getButtonPosition = (position?: "left" | "center" | "right") => {
    if (position === "left") {
      return "flex-start";
    } else if (position === "center") {
      return "center";
    } else if (position === "right") {
      return "flex-end";
    } else return "";
  };

  return (
    <Formik
      initialValues={{
        FirstName: props.initialUser?.FirstName || "",
        LastName: props.initialUser?.LastName || "",
        Email: props.initialUser?.Email || "",
        Phone: props.initialUser?.Phone || "",
        CurrentPassword: "",
        Password: props?.initialUser?.Password || "",
        PasswordConfirmation: "",
      }}
      validationSchema={buildValidation(props.fields)}
      onSubmit={props.type === "register" ? handleRegister : handlePatchUser}
    >
      {({ values, errors, touched, isValid, isSubmitting, handleBlur, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.flexRow}>
            {hasField("FirstName") && (
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoFocus
                id="first"
                label="First Name*"
                name="FirstName"
                value={values.FirstName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.FirstName && Boolean(errors.FirstName)}
                helperText={touched.FirstName && errors.FirstName}
              />
            )}
            {hasField("LastName") && (
              <TextField
                margin="dense"
                variant="outlined"
                id="last"
                name="LastName"
                value={values.LastName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.LastName && Boolean(errors.LastName)}
                helperText={touched.LastName && errors.LastName}
                label="Last Name*"
                fullWidth
              />
            )}
          </div>
          {hasField("Email") && (
            <Fragment>
              {hasField("CurrentPassword") && (
                <TextField
                  margin="dense"
                  variant="outlined"
                  id="CurrentPassword"
                  name="CurrentPassword"
                  label="Current Password*"
                  type="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.CurrentPassword && Boolean(errors.CurrentPassword)}
                  helperText={touched.CurrentPassword && errors.CurrentPassword}
                  fullWidth
                ></TextField>
              )}
              {passwordResetError && (
                <Typography variant="body2" color="error">
                  {passwordResetError}
                </Typography>
              )}
              <TextField
                margin="dense"
                variant="outlined"
                id="Email"
                name="Email"
                value={values.Email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.Email && Boolean(errors.Email)}
                helperText={touched.Email && errors.Email}
                label="Email Address*"
                type="Email"
                fullWidth
              />
            </Fragment>
          )}
          {hasField("Phone") && (
            <TextField
              margin="dense"
              variant="outlined"
              id="Phone"
              name="Phone"
              value={values.Phone}
              InputProps={{ inputComponent: PhoneMaskInput as any }}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.Phone && Boolean(errors.Phone)}
              helperText={touched.Phone && errors.Phone}
              label="Phone Number*"
              type="text"
              fullWidth
            />
          )}
          {hasField("Password") && (
            <Fragment>
              {hasField("CurrentPassword") && (
                <TextField
                  margin="dense"
                  variant="outlined"
                  id="CurrentPassword"
                  name="CurrentPassword"
                  label="Current Password*"
                  type="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.CurrentPassword && Boolean(errors.CurrentPassword)}
                  helperText={touched.CurrentPassword && errors.CurrentPassword}
                  fullWidth
                ></TextField>
              )}
              {passwordResetError && (
                <Typography variant="body2" color="error">
                  {passwordResetError}
                </Typography>
              )}
              <TextField
                margin="dense"
                variant="outlined"
                id="Password"
                name="Password"
                label="Password*"
                type="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.Password && Boolean(errors.Password)}
                helperText={touched.Password && errors.Password}
                // helperText="Passwords must be at least 8 characters long and include at least one letter and one number. Passwords can also include the following special characters: ! @ # $ %"
                fullWidth
              />
              <DialogContentText>
                <Typography variant="body1">
                  Your password needs to include a minimum of 10 characters, and each of the following attributes:
                </Typography>
                <PasswordRequirementsList />
              </DialogContentText>
              <TextField
                margin="dense"
                variant="outlined"
                id="PasswordConfirmation"
                name="PasswordConfirmation"
                label="Confirm Password*"
                type="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.PasswordConfirmation && Boolean(errors.PasswordConfirmation)}
                helperText={touched.PasswordConfirmation && errors.PasswordConfirmation}
                fullWidth
              />
            </Fragment>
          )}
          {isValid}
          <Box display="flex" mt={2} justifyContent={getButtonPosition(props.buttonPosition)}>
            {props.type === "update" && (
              <Button variant="text" onClick={handleCancel} size="small">
                Cancel
              </Button>
            )}
            <DoubleOutlinedBtn
              buttonText={props.type === "register" ? "Create An Account" : "Save"}
              buttonProps={{
                disabled:
                  isSubmitting || !isValid || (hasField("CurrentPassword") && values.CurrentPassword.length < 3),
                type: "submit",
              }}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default UserInputForm;
