import appConstants from "../constants/app.constants";
import $ from "jquery";
import deliveryResourceService from "./delivery-resource.service";
import { cloneDeep } from "lodash";
import productService from "./product.service";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import { HomeContentState } from "../providers/contentful";
import { BachmansCategory } from "../models/Category";
import { SeoInfo } from "../components/StaticPages/SocialLinks";
import { BachmansProduct } from "../models/product";

var seoPages: any;
const seoDataProps = {
  MetaTitle: "",
  MetaDescription: "",
  MetaType: "website",
  MetaSiteName: window.location.host.toLowerCase().trim().replace("www.", ""),
  MetaImage: "assets/images/default-seo-img.jpg",
  CanonicalUrl: "",
};
const homepageSEO = cloneDeep(seoDataProps);
const errorSEO = cloneDeep(seoDataProps);

async function initialize() {
  try {
    await initDefaultPageConstants();
    const buyerXp = await deliveryResourceService.GetBuyerXp();

    if (buyerXp && buyerXp.SEOPages) {
      seoPages = buyerXp.SEOPages;
      if (seoPages["Home Page"]?.seo) {
        seoPages["Home Page"].seo.MetaTitle = homepageSEO.MetaTitle;
        seoPages["Home Page"].seo.MetaDescription = homepageSEO.MetaDescription;
      }
    } else {
      console.log("Could not find the buyer with buyerId:", appConstants.buyerId);
      setSeoDataProps(errorSEO);
    }
  } catch (exception) {
    console.log("SEO INIT ERROR", exception);
    setSeoDataProps(errorSEO);
  }
}

async function initDefaultPageConstants() {
  //errorSEO.props data
  errorSEO.MetaTitle = "Not found";
  errorSEO.MetaDescription = "The page you are looking for could not be found.";
  errorSEO.MetaImage = "assets/images/default-seo-img.jpg";

  //homepageSEO.props data
  homepageSEO.MetaTitle = "Welcome to Bachmans.com";
  homepageSEO.MetaDescription =
    "Shop for floral arrangements, indoor plants, gifts, decor, and garden plants and supplies from your trusted neighborhood garden center.";
  homepageSEO.MetaImage = "assets/images/default-seo-img.jpg";
}

async function setSeoData(seoData: any, seoDataContentful?: HomeContentState) {
  await initialize();

  if (seoData === "404") {
    return setSeoDataProps(errorSEO);
  }

  if (seoData === "Home Page" && seoDataContentful?.data?.fields?.seo) {
    return setSeoDataProps(seoDataContentful.data.fields.seo);
  }

  if (seoPages && seoPages[seoData]) {
    return setSeoDataProps(seoPages[seoData].seo);
  }

  if (seoDataContentful?.data?.fields?.seo) {
    return setSeoDataProps(seoDataContentful.data.fields.seo);
  }

  if (typeof seoData === "object") {
    return setSeoDataProps(seoData);
  }

  return setSeoDataProps(homepageSEO);
}

async function setSeoDataProps(seoObject: any) {
  seoDataProps.CanonicalUrl = window.location.href.toLowerCase().trim().replace("http://", "https://");

  if (seoObject) {
    seoDataProps.MetaTitle = seoObject.MetaTitle || seoObject.title || seoDataProps.MetaTitle;
    seoDataProps.MetaDescription = seoObject.MetaDescription || seoObject.description || seoDataProps.MetaDescription;

    var metaImage = seoObject.MetaImage || seoObject.image || seoDataProps.MetaImage;
    if (metaImage.toLowerCase().trim().includes("images.ctfassets.net")) {
      metaImage = metaImage.trim();
      metaImage =
        !metaImage.includes("http:") && !metaImage.includes("https:")
          ? "https:" + metaImage
          : metaImage.replace("http:", "https:");
      seoDataProps.MetaImage = metaImage;
    } else {
      seoDataProps.MetaImage = homepageSEO.MetaImage;
    }
    seoDataProps.MetaType = seoObject.MetaType ? seoObject.MetaType : seoDataProps.MetaType;
  } else {
    seoDataProps.MetaTitle = homepageSEO.MetaTitle;
    seoDataProps.MetaDescription = homepageSEO.MetaDescription;
    seoDataProps.MetaImage = homepageSEO.MetaImage;
    seoDataProps.MetaType = homepageSEO.MetaType;
  }

  $("title").text(seoDataProps.MetaTitle);
  $("[property='og:title'], [property='twitter:title']").attr("content", seoDataProps.MetaTitle);
  $("[name='description'], [property='twitter:description']").attr("content", seoDataProps.MetaDescription);
  $("[property='og:image'], [property='twitter:image']").attr("content", seoDataProps.MetaImage);
  $("[property='og:type']").attr("content", seoDataProps.MetaType);
  $("[property='og:site_name'], [property='twitter:site']").attr("content", seoDataProps.MetaSiteName);
  $("#canonical-tag, [property='og:url']").attr("href", seoDataProps.CanonicalUrl);

  //console.log(seoDataProps);
  return seoDataProps;
}

const getSeoDataProps = () => {
  return setSeoDataProps(null);
};

export type PageGroupKey = "category" | "product" | "services-pages" | "event-details";

async function importIntoSeoDataProps(seoObject: any, pageGroupKey: PageGroupKey) {
  await initialize();
  const pageSeo = cloneDeep(homepageSEO);

  if (!seoObject || !pageGroupKey) {
    setSeoDataProps(pageSeo);
    return;
  }

  const updateSeoData = (title: string | undefined, description: string | undefined, image?: string) => {
    pageSeo.MetaTitle = title || pageSeo.MetaTitle;
    pageSeo.MetaDescription = description || pageSeo.MetaDescription;
    if (image) pageSeo.MetaImage = image;
    setSeoDataProps(pageSeo);
  };

  switch (pageGroupKey) {
    case "category":
      const category = seoObject as BachmansCategory;
      updateSeoData(
        category.xp?.seo?.MetaTitle || category.Name,
        category.xp?.seo?.MetaDescription || category.Description
      );
      break;

    case "product":
      const product = seoObject as BuyerProduct;
      updateSeoData(
        product?.xp?.SEO?.MetaTitle || product.Name,
        product.xp?.SEO?.MetaDescription || product.Description,
        productService.GetDefaultImage(product)
      );
      break;

    case "event-details":
      const event = seoObject as BachmansProduct;
      updateSeoData(
        event?.xp?.SEO?.Title || event.Name,
        event.xp?.SEO?.Description || event.Description,
        productService.GetDefaultImage(event)
      );
      break;

    case "services-pages":
      const servicePage = seoObject as SeoInfo;
      const pathname = window.location.pathname.toLowerCase().trim();
      if (pathname.includes("/p/")) {
        updateSeoData(
          $("[property='og:title']").attr("content"),
          $("[name='description']").attr("content"),
          servicePage.image
        );
      } else {
        updateSeoData(servicePage.title, servicePage.description, servicePage.image);
      }
      break;

    default:
      setSeoDataProps(pageSeo);
  }
}

const service = {
  SetSeoData: setSeoData,
  GetSeoDataProps: getSeoDataProps,
  ImportIntoSeoDataProps: importIntoSeoDataProps,
};

export default service;
