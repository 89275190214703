import { CssBaseline } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { withCookies } from "react-cookie";

import Footer from "./components/Layout/Footer/Footer";
import Header from "./components/Layout/Header/Header";
import MainContent from "./components/Layout/MainContent";
import ScrollToTop from "./components/Layout/ScrollToTop";
import ContentfulProvider from "./providers/contentful";
import SessionProvider from "./providers/session";
import CatalogProvider from "./providers/catalog";
import { Helmet } from "react-helmet";
import ErrorBoundary from "./components/Shared/ErrorBoundary";

const App: FunctionComponent = () => {
  useEffect(() => {
    document.title = process.env.REACT_APP_ENVIRONMENT === "production" ? "Bachmans" : "Bachmans Test";
  }, []);

  return (
    <ContentfulProvider>
      <SessionProvider>
        <CatalogProvider>
          <Router>
            <ScrollToTop>
              <CssBaseline />
              <Helmet></Helmet>
              <Header></Header>
              <ErrorBoundary>
                <MainContent></MainContent>
              </ErrorBoundary>
              <Footer></Footer>
            </ScrollToTop>
          </Router>
        </CatalogProvider>
      </SessionProvider>
    </ContentfulProvider>
  );
};

export default withCookies(App);
