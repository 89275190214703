import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import { Address, LineItem, MeUser, RequiredDeep, Tokens } from "ordercloud-javascript-sdk";
import BachmansOrder from "../models/order";
import { BachmansShipmentWithLineItems } from "../models/shipment";
import { BuyerXp } from "../models/buyerXp";
import { BachmansMeUser, RegisterUserResponse } from "../models/user";
import appConfig from "../constants/app.constants";
import { AvalaraAddressReq, AvalaraResponse } from "../models/Avalara";
import { BachmansPay } from "../models/Payment";
import { BachmansCategory } from "../models/Category";

export interface OrderCalculateResponse {
  Order: BachmansOrder;
  CalculatorShipments: BachmansShipmentWithLineItems[];
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

class bachmansIntegrations {
  private axios: AxiosInstance;
  constructor() {
    this.axios = axios.create({
      baseURL: appConfig.bachmansIntegrationsUrl,
    });
  }
  private buildConfig = (): AxiosRequestConfig => {
    return { headers: { Authorization: `Bearer ${Tokens.GetAccessToken()}` } };
  };

  private orderCalculate = async (orderId: string) => {
    return this.axios
      .post<OrderCalculateResponse>(
        "/api/calculateorder",
        {
          OrderID: orderId,
          OrderDirection: "Outgoing",
        },
        {
          headers: {
            Authorization: `Bearer ${Tokens.GetAccessToken()}`,
          },
        }
      )
      .then((response) => response.data);
  };

  private orderSubmit = async (orderId: string, approvedTotal: number, payments: BachmansPay) => {
    return this.axios.post<OrderCalculateResponse>(
      "/api/submitorder",
      {
        OrderID: orderId,
        OrderDirection: "Outgoing",
        ApprovedTotal: approvedTotal,
        BachmansPayments: payments,
      },
      {
        headers: {
          Authorization: `Bearer ${Tokens.GetAccessToken()}`,
        },
      }
    );
  };

  public order = {
    calculate: this.orderCalculate,
    submit: this.orderSubmit,
  };

  public upsertLineItem = async (orderID: string, lineItemReq: LineItem) => {
    let response = await this.axios.put<LineItem>(`/api/order/${orderID}/lineitems`, lineItemReq, this.buildConfig());
    return response.data;
  };

  public updateCCAsync = async (ccID: string, ccRequestBody: any) => {
    return await this.axios.post<any, AxiosResponse<any>>(`/api/creditcard/${ccID}`, ccRequestBody, this.buildConfig());
  };
  public deleteCCAsync = async (ccID: string) => {
    return await this.axios.delete(`/api/creditcard/${ccID}`, this.buildConfig());
  };

  public getCatalogCache = async (catalogID: string) => {
    return this.axios.get<RequiredDeep<BachmansCategory>[]>(`api/catalogs/${catalogID}`, {
      headers: { Authorization: `Bearer ${Tokens.GetAccessToken()}` },
    });
  };

  public getBuyerXp() {
    const url = appConfig.blobStorageUrl as string;
    return axios.get<BuyerXp>(`${url}/buyerxp.json`, {
      params: { guid: guid() },
    });
  }

  public getColorMap = async () => {
    const url = appConfig.blobStorageUrl as string;
    return axios.get<any>(`${url}/colors.json`, {
      params: { guid: guid() },
    });
  };

  public searchGoogleAddress = async (address: string) => {
    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      `&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    return axios.get(url);
  };

  public registerUserAsync = async (user: BachmansMeUser): Promise<RegisterUserResponse> => {
    let token = Tokens.GetAccessToken();
    let response = await this.axios.post<BachmansMeUser, AxiosResponse<RegisterUserResponse>>(
      `/api/register?token=${token}`,
      user,
      this.buildConfig()
    );
    return response.data;
  };

  // public GetPurplePerks = async (loyaltyID: string) => {
  //   const url = appConfig.jitterbitUrl + "/PurplePerksBalanceCheck";
  //   return axios.post(url, { card_number: `777777${loyaltyID}` });
  // };

  // !todo - cleanup this commented code
  // public GetPurplePerks = async (LoyaltyCardNumber: string) => {
  //   const url = appConfig.bachmansIntegrationsUrl + "/api/cecs/customerloyaltyaccountinfobycardnumber";
  //   console.log("LoyaltyCardNumber", LoyaltyCardNumber);
  //   return axios.post(url, { cardNumber: LoyaltyCardNumber });
  // };

  public GetPurplePerks = async (user: MeUser<any>) => {
    const eagleUser = Boolean(user?.xp?.LoyaltyID && !user?.xp?.LoyaltyCardNumber);
    // for CECS customer Perks account details
    const purple_perks_url = appConfig.bachmansIntegrationsUrl + "/api/cecs/customerloyaltyaccountinfobycardnumber";
    //  for Eagle customer Perks account details
    const purple_perks_url_for_eagle_user =
      appConfig.bachmansIntegrationsUrl + "/api/cecs/customerprofilebylegacycustomerid";

    if (eagleUser) {
      const response = await axios.post(purple_perks_url_for_eagle_user, { customerId: user?.xp?.EagleCustomerNumber });
      return {
        data: {
          ...response.data,
          EagleUser: true,
        },
      };
    }

    return axios.post(purple_perks_url, { cardNumber: user?.xp?.LoyaltyCardNumber });
  };

  public verifyRecaptcha = async (value: string) => {
    return this.axios.post(`/api/recaptcha/${value}`, this.buildConfig());
  };

  public updateEagleCustomer = async (user: any, action: string) => {
    console.log("user Eagle File Drop", user);
    const url = appConfig.jitterbitUrl + "/four51_to_eagle_filecreate";
    const data = {
      CustomerID: user.xp && user.xp.EagleCustomerNumber ? user.xp.EagleCustomerNumber.toString() : user.ID,
      OMSAccountNumber: user.ID,
      Action: action,
    };
    return axios.post(url, data);
  };

  public validateAddress = async (address: Address): Promise<AvalaraResponse> => {
    const toValidate: AvalaraAddressReq = {
      addressline1: address.Street1,
      addressline2: address.Street2 ? address.Street2 : null,
      city: address.City,
      zipcode: address.Zip,
      country: address.Country,
      state: address.State,
    };
    const res = await this.axios.post<AvalaraAddressReq, AxiosResponse<AvalaraResponse>>(
      `/api/Avalara/AddressValidation`,
      toValidate,
      this.buildConfig()
    );
    return res.data;
  };

  public validateGiftCard = async (giftCardCount: number, PinEnabled?: boolean): Promise<GiftCardValidation> => {
    const config = this.buildConfig();
    config.params = { count: giftCardCount, PinEnabled: PinEnabled };
    const res = await this.axios.get("/api/giftcards/validate", config);
    return res.data;
  };

  // public giftCardBalance = async (cardNumber: string): Promise<any> => {
  //   const url = appConfig.jitterbitUrl + "/GiftCardBalance";
  //   const data = {
  //     card_number: cardNumber,
  //   };
  //   const result = await axios.post(url, data);

  //   //EK: Keep for now for testing Gift Cards.
  //   // A giftcard of 1234 will always return a valid card with $100 (in test)
  //   if (cardNumber === "1234" && process.env.NODE_ENV !== "production") {
  //     return {
  //       data: {
  //         card_value: 100,
  //       },
  //     };
  //   } else return result;
  // };
  public giftCardBalance = async (cardNumber: string): Promise<any> => {
    //EK: Keep for now for testing Gift Cards.
    // A giftcard of 1234 will always return a valid card with $100 (in test)
    if (cardNumber === "1234" && process.env.NODE_ENV !== "production") {
      return {
        data: {
          return: {
            AccountState: {
              Balance: {
                Amount: "100",
              },
            },
          },
        },
      };
    }
    const url = appConfig.bachmansIntegrationsUrl + "/api/cecs/inquirygiftcard";
    const data = {
      cardNumber: cardNumber,
    };
    const result = await axios.post(url, data);

    return result;
  };

  public BraintreeToken = async () => {
    const res = await this.axios.get("/api/braintree");
    return res.data;
  };

  public getCECSCustomerID = async (userID: string): Promise<any> => {
    const url = appConfig.bachmansIntegrationsUrl + "/api/cecs/searchcustomer/" + userID;
    try {
      const result = await axios.get(url);
      return result;
    } catch {
      return null;
    }
  };
}

export interface GiftCardValidation {
  IsValid: boolean;
  NumberAvailable: number;
}

export default new bachmansIntegrations();
