import axios, { AxiosInstance } from "axios";
import { BachmansMeUser } from "../models/user";
import appConfig from "../constants/app.constants";

type EagleAction = "registerpp";
class JitterbitIntegrations {
  private axios: AxiosInstance;
  constructor() {
    this.axios = axios.create({
      baseURL: appConfig.jitterbitUrl,
    });
  }

  public checkGcBalance = async (gcNumber: string) => {
    const url = appConfig.bachmansIntegrationsUrl + "/api/cecs/inquirygiftcard";
    const data = {
      cardNumber: gcNumber,
    };
    return this.axios.post(url, data);
  };

  public RegisterPurplePerks = async (user: BachmansMeUser, action: EagleAction) => {
    let data = {
      CustomerID: user.xp && user.xp.EagleCustomerNumber ? user.xp.EagleCustomerNumber.toString() : user.ID,
      OMSAccountNumber: user.ID,
      Action: action,
    };
    return this.axios.post("/four51_to_eagle_filecreate", data);
  };

  public RegisterPurplePerksCECS = async (user: BachmansMeUser) => {
    let data = {
      ID: user.xp && user.xp.CecsCustomerNumber ? user.xp.CecsCustomerNumber.toString() : user.ID,
    };
    return this.axios.post(appConfig.bachmansIntegrationsUrl + "/api/cecs/customerenrolltoloyalty", data);
  };
}

export default new JitterbitIntegrations();
