import React, { Fragment, FunctionComponent, useContext, useState } from "react";
import {
  Checkbox,
  createStyles,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Formik, useFormikContext, FormikHelpers } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useCookies } from "react-cookie";
import { Me } from "ordercloud-javascript-sdk";
import { SessionContext } from "../../providers/session";
import bachDateTime from "../../services/bachDateTime.service";
import { AuthDialogTab } from "./AuthDialog";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import BachmansLoading from "../Shared/BachmansLoading";
import { Errors } from "../../constants/error.constants";
import bachmansIntegrationsService from "../../services/bachmansIntegrations.service";

interface LoginProps {
  onTabChange: (newTab: AuthDialogTab) => (e: React.MouseEvent) => void;
  changeTab: (newTab: AuthDialogTab, mustResetPassword?: boolean) => void;
  onClose: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    flexRow: {
      display: "flex",
      flexFlow: "row nowrap",
      margin: theme.spacing(0, -1),
      alignItems: "center",
      "& > *": {
        flex: "1 1 auto",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    rememberMe: {
      marginLeft: -theme.spacing(0.5),
    },
  })
);

interface FormValues {
  email: string;
  password: string;
  showRecaptcha: boolean;
  recaptcha: string;
  rememberMe: boolean;
}

const Login: FunctionComponent<LoginProps> = (props) => {
  const classes = useStyle();
  const { onTabChange, onClose, changeTab } = props;
  const { login, cookiePrefix } = useContext(SessionContext);
  const [cookies, removeCookie] = useCookies();
  // const { order } = useSelector((state: RootState) => state.order);
  const [loginCount, setLoginCount] = useState(0);
  const [displayRecaptcha, setDisplayRecaptcha] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
  const [pageInfo, setPageInfo] = useState({ loading: false, error: false, message: "" });
  const [loading, setLoading] = useState(false);
  const { patchUser } = useContext(SessionContext);

  const RecaptchaDisplay = () => {
    const { setFieldValue, validateForm, values, errors, touched } = useFormikContext<FormValues>();
    React.useEffect(() => {
      if (!values.showRecaptcha && displayRecaptcha) {
        setFieldValue("showRecaptcha", true);
      }
    }, [setFieldValue, values.showRecaptcha]); //intentionally leaving out showRecaptcha since it triggers too much

    return (
      <Fragment>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={(value) => {
            if (value) {
              // Uncomment this once we have recaptcha account updated with new domains

              bachmansIntegrationsService.verifyRecaptcha(value).then((res: any) => {
                if (res.success) {
                  setRecaptchaResponse(value);
                  setFieldValue("showRecaptcha", false);
                  setDisplayRecaptcha(false);
                  validateForm();
                }
              });
              setRecaptchaResponse(value);
              setFieldValue("showRecaptcha", false);
              setDisplayRecaptcha(false);
              validateForm();
            }
          }}
        />
        {touched.recaptcha && Boolean(errors.recaptcha) && (
          <DialogContentText color="error">{errors.recaptcha}</DialogContentText>
        )}
      </Fragment>
    );
  };

  const validationSchema = yup.object({
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    password: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required"),
    showRecaptcha: yup.boolean(),
    recaptcha: yup.string().when("showRecaptcha", {
      is: true,
      then: yup.string().required("Must enter recatpcha"),
    }),
    rememberMe: yup.boolean(),
  });

  const handleLogin = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (login) {
      setLoading(true);
      //TODO: transfer anon order

      if (cookies[`${cookiePrefix}.loginfailed`] && recaptchaResponse === "") return;
      try {
        await login(values.email, values.password, values.rememberMe);
        if (cookies[`${cookiePrefix}.loginfailed`]) {
          removeCookie(`${cookiePrefix}.loginfailed`, cookies[`${cookiePrefix}.loginfailed`]);
        }
        //TODO: Add Applicatoin insight to set user credentials
        //TODO: handle reset by token
        // if ( roles.length === 1 && roles[ 0 ] === 'PasswordReset' ) {
        //   vm.token = data.access_token;
        //   vm.resetByToken = true;}
        // order?.LineItemCount ?

        const loggedInUser = await Me.Patch({ xp: { LastLoginTimeStamp: bachDateTime.Today().format("YYYY-MM-DD") } });
        //*** Setting CECS Customer Number for Old User ***/
        if (loggedInUser?.ID) {
          console.log("Login Page: loggedInUser.ID:" + loggedInUser.ID);
          const returnedUser = await bachmansIntegrationsService.getCECSCustomerID(loggedInUser.ID);
          //console.log("Login Page: returnedUser:", returnedUser);
          const cecsnumber = returnedUser?.data?.xp?.CecsCustomerNumber;
          cecsnumber && patchUser && (await patchUser({ xp: { CecsCustomerNumber: cecsnumber } }));
        }

        onClose();
        setSubmitting(false);
      } catch (err: any) {
        setLoginCount(loginCount + 1);
        if (err.isOrderCloudError) {
          const errorMessage = err.errors?.Errors?.[0].Message;
          if (errorMessage === Errors.passwordNotMeetSecurity) {
            changeTab("forgot", true);
          } else {
            setPageInfo({ loading: false, error: true, message: err.errors?.Errors?.[0].Message });
          }
        } else {
          console.error(err);
        }
        setSubmitting(false);
        if (loginCount > 2) {
          setDisplayRecaptcha(true);
        }
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography variant="h1">Log In</Typography>
      </DialogTitle>
      {loading ? (
        <BachmansLoading />
      ) : (
        <Formik
          initialValues={{
            email: cookies[`${cookiePrefix}.rememberme`] || "",
            password: "",
            showRecaptcha: false,
            recaptcha: "",
            rememberMe: Boolean(cookies[`${cookiePrefix}.rememberme`]) || false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  margin="dense"
                  autoFocus
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email Address*"
                  type="email"
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  id="password"
                  name="password"
                  label="Password*"
                  type="password"
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {pageInfo.error && pageInfo.message && (
                  <DialogContentText color="error">{pageInfo.message}</DialogContentText>
                )}
                <DialogContentText className={classes.flexRow}>
                  <FormControlLabel
                    className={classes.rememberMe}
                    control={
                      <Checkbox
                        id="rememberMe"
                        name="rememberMe"
                        value={values.rememberMe}
                        checked={values.rememberMe}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />

                  <Link href="#" align="right" onClick={onTabChange("forgot")}>
                    Reset password
                  </Link>
                </DialogContentText>
                {displayRecaptcha && <RecaptchaDisplay />}
                <DoubleOutlinedBtn
                  buttonText="Log In"
                  buttonProps={{
                    type: "submit",
                    disabled: isSubmitting || !isValid,
                  }}
                  styleProps={{
                    margin: "16px 0",
                    padding: "4px 32px",
                  }}
                />
                <Typography variant="body1">
                  New to Bachman's?
                  <Link style={{ marginLeft: 4 }} href="#" onClick={onTabChange("register")}>
                    Create an account!
                  </Link>
                </Typography>
              </DialogContent>
            </form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default Login;
