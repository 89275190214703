import React, { FunctionComponent, useState } from "react";
import { createStyles, makeStyles, Theme, Box, Typography } from "@material-ui/core";

import bachPP, { PurplePerksAccount } from "../../services/bachmansPurplePerks.service";
import { useEffect, useContext } from "react";
import { SessionContext } from "../../providers/session";
import PurpleBullets from "../Shared/PurpleBullets";
import purplePerksLogo from "../../assets/images/purple_perks.png";
import Link from "../Shared/Link";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import BachmansLoading from "../Shared/BachmansLoading";
import { clone } from "lodash";
import { BachmansMeUser } from "../../models/user";
import jitterbitService from "../../services/jitterbit.service";

interface PurplePerksProps {}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    purplePerksContainer: {
      padding: theme.spacing(3),
      border: "2px solid lightgrey",
      marginBottom: theme.spacing(3),
    },
    imgPurplePerksContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    imgPurplePerks: {
      display: "block",
      width: "clamp(45px, 5vw, 90px) !important",
    },
    purplePerksHeader: {
      marginLeft: theme.spacing(2),
    },
    purplePerksBox: {
      backgroundColor: theme.palette.primary.light,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    boxItem: {
      paddingLeft: theme.spacing(3),
      flex: "1 1 0",
    },
    boxItemRight: {
      paddingLeft: theme.spacing(2),
      flex: "1 1 0",
      borderLeft: "1px solid white",
    },
  })
);
const PurplePerks: FunctionComponent<PurplePerksProps> = (props) => {
  const classes = useStyle();
  const [purplePerksAccount, setPurplePerksAccount] = useState<PurplePerksAccount>();
  const { user, patchUser } = useContext(SessionContext);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (user) {
        let ppAccount = await bachPP.CheckBalanceAsync(user);

        if (ppAccount.EagleUser) {
          patchUser &&
            (await patchUser({
              xp: { LoyaltyCardNumber: ppAccount?.CardNumber, CECSAwardAccountID: ppAccount.CECSAwardAccountID },
            }));
        }
        setPurplePerksAccount(ppAccount);
      }
    })();
  }, [user]);

  // ! TODO: remove this function
  // const getLoyaltyID = (user: BachmansMeUser) => {
  //   if (user.Phone) {
  //     return clone(user.Phone)
  //       .replace(/[^\w\s]/gi, "")
  //       .replace(" ", "");
  //   }
  // };

  const register = async (user: BachmansMeUser) => {
    if (patchUser) {
      setLoading(true);
      try {
        if (user) {
          let ppAccount = await jitterbitService.RegisterPurplePerksCECS(user);
          const updatedUser = await patchUser({ xp: { LoyaltyCardNumber: ppAccount.data } });
          console.log("updatedUser", updatedUser);
          if (updatedUser) {
            let ppAccount = await bachPP.CheckBalanceAsync(updatedUser);
            ppAccount?.CECSAwardAccountID && (await patchUser({ xp: { CECSAwardAccountID: ppAccount?.CECSAwardAccountID } }));
            setPurplePerksAccount(ppAccount);
          }
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {user?.xp?.LoyaltyCardNumber ? (
        <div className={classes.purplePerksContainer}>
          <Box display="flex">
            <div className={classes.imgPurplePerksContainer}>
              <img className={classes.imgPurplePerks} src={purplePerksLogo} alt="Purple Perks Badge" />
            </div>
            <div>
              <Typography variant="h2">My Purple Perks</Typography>
              <Typography variant="body1">You can use your Purple Perks balance during checkout</Typography>
            </div>
          </Box>
          <Box display="flex" className={classes.purplePerksBox}>
            <div className={classes.boxItem}>
              <Typography variant="body1">Dollars Available</Typography>
              <Typography variant="body1">
                <strong>{`$${purplePerksAccount?.Balance}`}</strong>
              </Typography>
            </div>
            <div className={classes.boxItemRight}>
              <Typography variant="body1">Expiration Date</Typography>
              <Typography variant="body1">
                <strong>{purplePerksAccount?.ExpirationDate}</strong>
              </Typography>
            </div>
          </Box>
          <PurpleBullets />
          <Link to="/customer-service/frequently-asked-questions">
            <strong>{"Get more Information & FAQs on Purple Perks >"}</strong>
          </Link>
        </div>
      ) : (
        <div>
          <Typography>
            <strong>Dear Customer,</strong> <br />
            You are not registered with our loyalty program.{" "}
          </Typography>
          {user && (
            <DoubleOutlinedBtn
              buttonText="Register for Purple Perks"
              buttonProps={{
                size: "small",
                onClick: () => register(user),
                disabled: loading,
              }}
              styleProps={{ marginTop: 16 }}
            ></DoubleOutlinedBtn>
          )}
          {loading && <BachmansLoading />}
        </div>
      )}
    </div>
  );
};
export default PurplePerks;
