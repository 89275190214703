import { createStyles, makeStyles, Theme } from "@material-ui/core";
import JwtDecode from "jwt-decode";
import { Auth, Configuration, DecodedToken, Me, MeUser, Tokens } from "ordercloud-javascript-sdk";
import React, { createContext, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import appConfig, { scope } from "../constants/app.constants";
import DOMPurify from "dompurify";
import deliveryResource from "../services/delivery-resource.service";
import BachmansLoading from "../components/Shared/BachmansLoading";
import { initOrder, transferAndInitOrder } from "../redux/slices/order";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import moment from "moment";
import { BuyerXp } from "../models/buyerXp";
import { useAppSelector } from "../redux/store-hook";

Configuration.Set({
  baseApiUrl: appConfig.apiUrl,
  clientID: appConfig.clientID,
  cookieOptions: {
    path: "/",
    prefix: `${appConfig.name}.${process.env.REACT_APP_ENVIRONMENT}`,
  },
});

const clientId = appConfig.clientID as string;

interface SessionContextInterface<UserXp = any> {
  login?: (username: string, password: string, rememberMe: boolean) => Promise<void>;
  logout?: () => Promise<void>;
  patchUser?: (patchObl: Partial<MeUser>) => Promise<MeUser | undefined>;
  setUserTokens?: (token: string) => void; //sets tokens to trigger fresh and set user in context
  setAnonTokens?: () => void; //set method to trigger refresh-
  anonymous: boolean;
  cookiePrefix: string;
  token?: string;
  user?: BachmansUser<UserXp>;
}

export interface BachmansUser<UserXp = any> extends MeUser<UserXp> {
  Orphaned: boolean;
}

export const SessionContext = createContext<SessionContextInterface>({
  anonymous: true,
  cookiePrefix: `${appConfig.name}.${process.env.REACT_APP_ENVIRONMENT}`,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPane: {
      height: "100vh",
      width: "100vw",
      display: "grid",
      placeItems: "center center",
      color: theme.palette.primary.main,
    },
  })
);

const SessionProvider: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const [user, setUser] = useState<BachmansUser>();
  const currentOrder = useAppSelector((state) => state.order);
  // eslint-disable-next-line
  const [tokenTimeout, setTokenTimeout] = useState<NodeJS.Timeout>();
  const [token, setToken] = useState(Tokens.GetAccessToken());
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch<AppDispatch>();
  const cookiePrefix = `${process.env.REACT_APP_NAME}.${process.env.REACT_APP_ENVIRONMENT}`;

  const isOrphaned = useCallback((meUser: MeUser, buyerXp: BuyerXp) => {
    const MAX_ALLOWED_DAYS_WITHOUT_SYNC = 0;

    const hasBeenSynced = (lastUserSyncTimestamp: string, lastSyncTimestamp: string): boolean => {
      const lastSyncMoment = moment.utc(lastSyncTimestamp).startOf("d");
      const lastUserSyncMoment = moment.utc(lastUserSyncTimestamp).startOf("d");
      const daysSinceLastSync = lastSyncMoment.diff(lastUserSyncMoment, "days", true);
      return daysSinceLastSync <= MAX_ALLOWED_DAYS_WITHOUT_SYNC;
    };

    const wasCreatedToday = (dateCreated: string): boolean => {
      const today = moment.utc().startOf("d");
      const createdMoment = moment.utc(dateCreated).startOf("d");
      return today.isSame(createdMoment, "d");
    };

    if (!meUser || meUser.ID === "299999") {
      return false;
    }
    if (meUser?.xp?.OrderCloudToCecsCustomerStatus && meUser?.xp?.OrderCloudToCecsCustomerStatus === "Success") {
      return false;
    }
    if (meUser?.xp?.CecsCustomerNumber) {
      return false;
    }
    if (
      meUser?.xp?.EagleToFour51CustomerUpdateTimeStamp &&
      hasBeenSynced(meUser?.xp?.EagleToFour51CustomerUpdateTimeStamp, buyerXp.LastCustomerSyncTimeStamp)
    ) {
      return false;
    }
    if (
      meUser?.xp?.CreatedFrom === "web" &&
      meUser?.xp?.Four51ToEagleCustomerFileStatus === "Success" &&
      wasCreatedToday(
        meUser?.xp?.Four51ToEagleCustomerFileStatus === "Success"
          ? meUser.xp.Four51ToEagleCustomerFileTimeStamp
          : meUser.DateCreated
      )
    ) {
      return false;
    }
    return true;
  }, []);

  const setBachmansUser = useCallback(
    (meUser: MeUser, buyerXp: BuyerXp) => {
      const bachUser: BachmansUser = {
        ...meUser,
        Orphaned: isOrphaned(meUser, buyerXp),
      };
      setUser(bachUser);
    },
    [isOrphaned]
  );

  // Inits by looking for validate token and setting anon or profiled User tokens
  const validateToken = useCallback(async () => {
    try {
      const validToken = await Tokens.GetValidToken();
      Tokens.SetAccessToken(validToken);
      setToken(validToken);
    } catch {
      const anon = await Auth.Anonymous(clientId, scope);
      Tokens.SetAccessToken(anon.access_token);
      Tokens.SetRefreshToken(anon.refresh_token);
      setToken(anon.access_token);
    } finally {
      deliveryResource.Init();
    }
  }, []);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const setAnonTokens = useCallback(async () => {
    const anon = await Auth.Anonymous(clientId, scope);
    Tokens.SetAccessToken(anon.access_token);
    setToken(anon.access_token);
    Tokens.SetRefreshToken(anon.refresh_token);
    return anon.access_token;
  }, []);

  //sets Anon or Profiled User tokens
  const attemptRefresh = useCallback(async () => {
    const refreshToken = Tokens.GetRefreshToken();
    if (refreshToken) {
      try {
        const refreshed = await Auth.RefreshToken(refreshToken, clientId);
        Tokens.SetAccessToken(refreshed.access_token);
        setToken(refreshed.access_token);
      } catch {
        await setAnonTokens();
      }
    }
  }, [setAnonTokens]);

  // if token is detected, create a time out & refresh user object and tokens
  const createTokenTimeout = useCallback(
    (decoded: DecodedToken) => {
      var currentSeconds = Date.now() / 1000;
      var currentSecondsWithBuffer = currentSeconds - 10;
      var refreshTimeInMilliseconds = (decoded.exp - currentSecondsWithBuffer) * 1000;
      return setTimeout(attemptRefresh, refreshTimeInMilliseconds);
    },
    [attemptRefresh]
  );

  useEffect(() => {
    if (token && !tokenTimeout) {
      const decoded = JwtDecode<DecodedToken>(token);
      setTokenTimeout(createTokenTimeout(decoded));
    } else if (tokenTimeout) {
      clearTimeout(tokenTimeout);
    }
  }, [token, createTokenTimeout, tokenTimeout]);

  const refreshUser = useCallback(async () => {
    try {
      const [meUser, buyerXp] = await Promise.all([Me.Get(), deliveryResource.GetBuyerXp()]);
      setBachmansUser(meUser, buyerXp);
      // //const meUser = await Me.Get();
      // setUser(meUser);
    } catch {
      setUser(undefined);
    }
  }, [setBachmansUser]);

  useEffect(() => {
    if (token) {
      refreshUser();
    }
  }, [token, refreshUser]);

  const handleRememberMe = useCallback(
    (rememberMe: boolean, username: string, refreshToken?: string) => {
      if (rememberMe) {
        // auth.refresh_token is not returning from the api
        // Tokens.SetRefreshToken(refreshToken);
        setCookie(`${cookiePrefix}.rememberme`, DOMPurify.sanitize(username));
      }
      if (cookies[`${cookiePrefix}.rememberme`] && !rememberMe) {
        removeCookie(`${cookiePrefix}.rememberme`);
      }
    },
    [cookiePrefix, setCookie, removeCookie, cookies]
  );

  const login = useCallback(
    async (username: string, password: string, rememberMe: boolean) => {
      try {
        const auth = await Auth.Login(username, password, clientId, scope);
        const currentAnonToken = Tokens.GetAccessToken();
        Tokens.SetAccessToken(auth.access_token);
        if (currentAnonToken && currentOrder?.order?.LineItemCount && currentOrder.order.LineItemCount > 0) {
          await dispatch(transferAndInitOrder(currentAnonToken));
        } else {
          await dispatch(initOrder());
        }
        Tokens.SetAccessToken(auth.access_token);
        handleRememberMe(rememberMe, username, auth.refresh_token);
        setToken(auth.access_token);
      } catch (err) {
        throw err;
      }
    },
    [currentOrder, dispatch, handleRememberMe]
  );

  const logout = useCallback(async () => {
    try {
      const anon = await Auth.Anonymous(clientId, scope);
      Tokens.SetAccessToken(anon.access_token);
      Tokens.SetRefreshToken(anon.refresh_token);
      setToken(anon.access_token);
    } finally {
      await dispatch(initOrder());
    }
  }, [dispatch]);

  const patchUser = useCallback(
    async (patchObject: Partial<MeUser>) => {
      try {
        const [update, buyerXp] = await Promise.all([Me.Patch(patchObject), deliveryResource.GetBuyerXp()]);
        setBachmansUser(update, buyerXp);
        return update;
      } catch (err) {
        console.error(err);
      }
    },
    [setBachmansUser]
  );

  const setUserTokens = useCallback((token: string) => {
    Tokens.SetAccessToken(token);
    Tokens.SetRefreshToken("");
    setToken(token);
  }, []);
  const contextValue = useMemo(() => {
    return {
      login,
      logout,
      patchUser,
      setUserTokens,
      setAnonTokens,
      token,
      user,
      cookiePrefix,
      anonymous: !user || user.ID === "299999",
    };
  }, [token, user, login, logout, patchUser, setUserTokens, setAnonTokens, cookiePrefix]);
  return (
    <SessionContext.Provider value={contextValue}>
      {contextValue.token ? (
        children
      ) : (
        <div className={classes.loadingPane}>
          <BachmansLoading />
        </div>
      )}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
